import React from "react";
// Customizable Area Start


// Customizable Area End

import ShoppingCartOrdersController, {
  Props,
  ShoppingCartOrder,
  ShoppingCartOrderItem,
} from "./ShoppingCartOrdersController";

export default class ShoppingCartOrders extends ShoppingCartOrdersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    return (
      <div style={{ backgroundColor: "rgba(0, 0, 0, 0.9)" }}>
        <div style={styles.cartContainer}>
          <div style={styles.cartHeader}>
            <h2>
              <span style={styles.highlight}>Your</span> Order
            </h2>
            <button style={styles.closeBtn}>×</button>
          </div>
          <div style={styles.cartItems}>
            {this.state.cartItems.map((item) => (
              <div key={item.id} style={styles.cartItem}>
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  style={styles.itemImage}
                />
                <div style={styles.itemDetails}>
                  <h3 style={{ margin: 0 }}>{item.name}</h3>
                  <p style={{ fontSize: "12px" }}>{item.description}</p>
                  <div style={styles.itemPrice}>
                    <span style={styles.originalPrice}>
                      ${item.price.toFixed(2)}
                    </span>
                    <span style={styles.discountedPrice}>
                      ${item.discountedPrice.toFixed(2)}
                    </span>
                  </div>
                  <div style={styles.quantityControlsContainer}>
                    <div style={styles.quantityControls} data-test-id="quantity-controlls">
                      <button
                        onClick={() => this.handleDecrement(item.id)}
                        style={styles.quantityButton}
                        data-test-id="decrement"
                      >
                        -
                      </button>
                      <span>{item.quantity}</span>
                      <button
                        onClick={() => this.handleIncrement(item.id)}
                        style={styles.quantityButton}
                        data-test-id="increment"
                      >
                        +
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div style={styles.cartFooter}>
            <div style={styles.totalContainer}>
              <h4>Total: ${this.calculateTotal().toFixed(2)}</h4>
            </div>
            <div>
              <button style={styles.checkoutBtn}>Go to Checkout</button>
            </div>
          </div>
        </div>
      </div>
    );
    // Customizable Area End
  }
}

// Customizable Area Start
const styles: { [key: string]: React.CSSProperties } = {
  cartContainer: {
    backgroundColor: "black",
    color: "white",
    padding: "20px",
    maxWidth: "400px",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    float: "right",
    fontFamily: "Roboto",
  },
  cartHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  highlight: {
    color: "yellow",
  },
  closeBtn: {
    background: "none",
    border: "none",
    fontSize: "24px",
    color: "white",
    cursor: "pointer",
  },
  cartItems: {
    marginTop: "20px",
    flex: 1,
  },
  cartItem: {
    display: "flex",
    marginBottom: "20px",
    borderBottom: "1px solid #555",
    paddingBottom: "20px",
  },
  itemImage: {
    width: "80px",
    height: "80px",
    borderRadius: "8px",
    marginRight: "10px",
  },
  itemDetails: {
    flex: 1,
  },
  itemPrice: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  originalPrice: {
    textDecoration: "line-through",
    color: "#888",
  },
  discountedPrice: {
    fontSize: "18px",
    color: "#f8b400",
  },
  quantityControlsContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  quantityControls: {
    display: "flex",
    alignItems: "center",
    marginTop: "10px",
    justifyContent: "space-around",
    backgroundColor: "#888",
    width: "100px",
    borderRadius: "10px",
  },
  quantityButton: {
    backgroundColor: "#888",
    color: "white",
    border: "none",
    padding: "5px 10px",
    cursor: "pointer",
  },
  totalContainer: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  cartFooter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px 0",
    borderTop: "1px solid #555",
  },
  checkoutBtn: {
    backgroundColor: "#f8b400",
    color: "black",
    border: "none",
    padding: "10px 20px",
    cursor: "pointer",
    borderRadius: "5px",
  },
}
// Customizable Area End
