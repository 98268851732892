import React from "react";
// Customizable Area Start
import { Box, Button, Typography } from "@mui/material"
import {
    createTheme,
} from "@mui/material/styles";
import Chip from '@mui/material/Chip';
import SwitchSelector from "react-switch-selector";

import Slider from "react-slick";


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../assets/overwrite-slider.css";
import CatalogueDetailsController, { Props } from "./CatalogueDetailsController.web";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class CatalogueDetails extends CatalogueDetailsController {
    constructor(props: Props) {
        super(props);
    }

    settings = {
        className: "center",
        centerMode: true,
        infinite: true,
        centerPadding: "0px",
        slidesToShow: 3,
        speed: 500,
        // variableWidth: 25,
        dots: true,
        arrows: false
    };

    render() {
        return (
            <Box className="container-slide" sx={{
                width: "100%", height: "400px", overflowY: "scroll", position: "relative"
            }}>
                <Typography variant="h6" sx={{
                    fontSize: '28px',
                    fontWeight: 700,
                }}>
                    <span style={{ color: "#C2B30A" }}>
                        New Specialty&nbsp;
                    </span>
                    <span style={{ color: "#ffffff", marginLeft: "3px" }}>
                        Resume
                    </span>
                </Typography>
                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}>
                    <div style={{
                        minHeight: "320px",
                    }}></div>
                    <div style={{
                        // minHeight: 0,
                        // minWidth: 0,
                        // padding: "20px",
                        // height: "50%",
                        width: "40%",
                        position: "absolute",
                        // '& .slick-dots': {
                        //     color: "red"
                        // }
                    }}>
                        <Slider {...this.settings}>
                            <div >
                                <img src={require('./../assets/small-slide.png')} />
                            </div>
                            <div >
                                <img src={require('./../assets/small-slide.png')} />
                            </div>
                            <div >
                                <img src={require('./../assets/small-slide.png')} />
                            </div>
                            <div >
                                <img src={require('./../assets/small-slide.png')} />
                            </div>
                            <div >
                                <img src={require('./../assets/small-slide.png')} />
                            </div>
                        </Slider>
                    </div>
                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div
                        style={{
                            height: 30,
                            width: 220
                        }}
                    > <SwitchSelector
                            backgroundColor="#57534E"
                            fontColor="#f9f9f9"
                            initialSelectedIndex={0}
                            onChange={() => { }}
                            options={[
                                {
                                    label: <Typography
                                        variant="h6" sx={{
                                            fontSize: '12px',
                                            fontWeight: 700,
                                        }}>
                                        Unavailable
                                    </Typography>,
                                    value: true
                                },
                                {
                                    label: <Typography
                                        variant="h6" sx={{
                                            fontSize: '12px',
                                            fontWeight: 700,
                                        }}>
                                        Available
                                    </Typography>,
                                    value: 20
                                }
                            ]}
                            selectedBackgroundColor="#C2B30A"
                            selectedFontColor="#ffffff"
                            selectionIndicatorMargin={3}
                        />
                    </div>
                    <Chip label="10% off" sx={{ backgroundColor: "#C2B30A", color: "#ffffff" }} />

                </Box>

                <Box sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end'
                }}>
                    <Box>

                    </Box>
                    <Box style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: "5px"
                    }}>
                        <Typography variant="h2" sx={{
                            fontSize: '18px',
                            fontWeight: 700,
                            color: "#A8A29E",
                            textDecoration: "line-through"
                        }}>
                            $20,00
                        </Typography>

                        <Typography variant="h6" sx={{
                            fontSize: '28px',
                            fontWeight: 700,
                            color: "#ffffff"
                        }}>
                            $18,00
                        </Typography>
                    </Box>
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Description:
                    </Typography>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: "24px"
                        }}>
                        Indulge in our rich, fudgy chocolate brownies! Made with high-quality butter, sugar, and cocoa powder, each bite melts in your mouth. Enhanced with vanilla extract and a hint of salt, these brownies are perfectly balanced.
                    </Typography>

                </Box>
                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Ingredients
                    </Typography>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '12px',
                            fontWeight: 400,
                            lineHeight: "24px"
                        }}>
                        Eggs / Vanilla extract / Flour / Cocoa powder / Chocolate chips
                    </Typography>
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Category
                    </Typography>
                    <Chip
                        style={{
                            borderRadius: '4px',
                            backgroundColor: "#78716C",
                            color: "#ffffff",
                            fontSize: '12px'
                        }} label={'Dessert Bars'}
                    />
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Variants
                    </Typography>
                    <Chip
                        style={{
                            borderRadius: '4px',
                            backgroundColor: "#78716C",
                            color: "#ffffff",
                            fontSize: '12px'
                        }} label={'Variant 1'}
                    />
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Extras
                    </Typography>
                    <Chip
                        style={{
                            borderRadius: '4px',
                            backgroundColor: "#78716C",
                            color: "#ffffff",
                            fontSize: '12px'
                        }} label={'Extra #1 $Price 0.1'}
                    />
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Allergens
                    </Typography>
                    <Chip
                        style={{
                            borderRadius: '4px',
                            backgroundColor: "#78716C",
                            color: "#ffffff",
                            fontSize: '12px'
                        }} label={'Dairy'}
                    />
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Estimated time of completion
                    </Typography>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: "24px"
                        }}>
                        45 min
                    </Typography>
                </Box>

                <Box my={2} sx={{
                    display: 'flex',
                    flexDirection: "column",
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                    marginBottom: "5px"
                }}>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px"
                        }}>
                        Is this Dish of the day?
                    </Typography>
                    <Typography
                        variant="h6" sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            lineHeight: "24px",
                            color: "#C2B30A"
                        }}>
                        No
                    </Typography>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>

                    <Button
                        sx={{
                            width: '343px',
                            '&:hover': {
                                backgroundColor: '#a59a00',
                            },
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            fontWeight: 700,
                            color: '#ffffff',
                            backgroundColor: '#C2B30A',
                            fontSize: '18px',
                            textTransform: 'none'
                        }}
                        fullWidth
                        onClick={() => this.handleContinue()}
                        variant="contained"
                        size="small"
                        data-test-id={"continueButton"}
                    >Continue
                    </Button>
                </Box>
                <Dialog
                    sx={{
                        borderRadius: '8px',
                        '& .MuiDialog-paper': {
                            backgroundColor: '#000000',
                        },
                        padding: '40px 16px',
                    }}

                    open={this.state.open}
                    aria-describedby="decline-modal-description"
                    aria-labelledby="decline-modal-title"
                >
                    <DialogTitle id="decline-modal-title">
                        <Typography variant="h6" sx={{
                            fontSize: '32px',
                            fontWeight: 700,
                            textAlign: "center"
                        }}>
                            <span style={{ color: "#C2B30A" }}>
                                New Specialty
                            </span>
                            <span style={{ color: "#ffffff" }}>
                                Created!!!
                            </span>
                        </Typography>
                        <Typography sx={{
                            fontSize: '14px',
                            padding: "5px 5px",
                            fontWeight: 400,
                            color: "#ffffff",
                            textAlign: "center"
                        }} variant="body1">
                            Congratulations! Your specialty has been successfully created and is ready to be savored.
                            Bon appétit!
                        </Typography>
                    </DialogTitle>

                    <DialogActions
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            gap: '16px',
                        }}
                    >
                        <Button
                            onClick={this.handleClose}
                            sx={{
                                width: '343px',
                                height: '55px',
                                padding: '16px 24px',
                                borderRadius: '8px',
                                color: '#ffffff',
                                backgroundColor: '#C2B30A',
                                '&:hover': {
                                    backgroundColor: '#a59a00',
                                },
                                fontSize: '18px',
                                fontWeight: 700,
                                textTransform: 'none'
                            }}
                            data-test-id={'catalogueBtn'}
                        >Go to Catalogue</Button>
                    </DialogActions>
                </Dialog>

            </Box >
        );
    }
}