import React from "react";
import { Box, Button, Typography } from "@mui/material";
import {
    createTheme,
} from "@mui/material/styles";
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import SortIcon from '@mui/icons-material/Sort';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import InputLabel from '@mui/material/InputLabel';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import PaymentsController, { Props } from "./PaymentsController.web";
import Paper from '@mui/material/Paper';
import SearchIcon from '@mui/icons-material/Search';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

export default class Catalogue extends PaymentsController {
    constructor(props: Props) {
        super(props);

    }

    createData = (
        Payment_ID: string,
        Description: string,
        State: any,
        Date: any,
        Hour: any,
        Total: any,

    ) => {
        return { Payment_ID, Description, State, Date, Hour, Total };
    }

    rows = [
        this.createData('#1654194', 'Payment Description', 'Done'
            , 'May 22, 2024', '13:23:22', '$46,00 usd'),
        this.createData('#1654194', 'Payment Description', 'Pending'
            , 'May 22, 2024', '13:23:22', '$46,00 usd'),
    ];

    render() {
        return (

            <Box sx={{
                width: '100%',
                height: "100%"
            }}>
                <Typography sx={{
                    fontSize: '25px',
                    fontWeight: 700,
                    mb:1
                }}>
                    <span style={{ color: "#C2B30A" }}>
                        Payment's
                    </span>
                    <span style={{ color: "#ffffff", marginLeft: "3px" }}>
                        History
                    </span>
                </Typography>

                <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
                    <Box sx={{ display: "flex", justifyContent: "flex-start", alignItems: "flex-start", width: "60%", gap: 3 }}>
                        <OutlinedInput
                            fullWidth
                            style={webStyle.inputLabel}
                            id="outlined-adornment-email"
                            type={'text'}
                            size="small"
                            placeholder={"Search"}
                            endAdornment={<SearchIcon />}
                            // value={values.email}
                            // onChange={handleChange("email")}
                            data-test-id='emailInput'
                            sx={{ width: '100%', overflow: 'hidden' }}
                        />

                        <FormControl sx={{ mx: 1, minWidth: 120 }} size="medium">
                            <Select
                                startAdornment={<SortIcon />}
                                IconComponent={() => <KeyboardArrowDownIcon sx={{ color: "#ffffff", mr: 1 }} />}
                                sx={webStyle.select}
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={this.state.token}
                                displayEmpty
                                label=""
                                size="small"
                                onChange={(v) => this.handleSelect(v)}
                            >
                                <MenuItem value="">
                                    Sort By
                                </MenuItem>
                                <MenuItem value={'one'}>Name</MenuItem>
                                <MenuItem value={'two'}>Date</MenuItem>
                            </Select>
                        </FormControl>
                        <FilterAltOutlinedIcon sx={webStyle.filterIcon} />
                    </Box>
                    <FormControl sx={{ mx: 1, minWidth: 120 }} size="medium">
                        <Select
                            sx={webStyle.select}
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={'one'}
                            label=""
                            size="small"
                            placeholder=""
                            onChange={(e) => this.handleSelect(e)}
                            displayEmpty
                            IconComponent={() => <KeyboardArrowDownIcon sx={{ color: "#ffffff", mr: 1 }} />}
                        >
                            <MenuItem value={'one'}>Last seven days</MenuItem>
                            <MenuItem value={'two'}>Last month</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Divider sx={{ borderColor: "#57534E", marginY: 2 }} />

                <Box sx={{
                    width: "100%"
                }}>
                    <Tabs
                        data-testid="tabs"
                        value={this.state.tab}
                        onChange={(e, v) => this.handleChange(e, v)}
                        aria-label="scrollable tabs example"
                        scrollButtons="auto"

                        sx={{
                            '& .MuiTabs-indicator': {
                                backgroundColor: '#C2B30A',
                            },
                            width: "100%"
                        }}
                    >
                        <Tab label="All"
                            sx={{
                                color: '#ffffff',
                                textTransform: 'none',
                                fontWeight: '700',
                                '&.Mui-disabled': {
                                    color: '#78716C',
                                    opacity: 1,
                                    pointerEvents: 'none',
                                },
                                '&.Mui-selected': {
                                    color: '#ffffff',
                                },
                                width: "20%"
                            }}
                            value="1" />
                        <Tab label="Pending"
                            sx={{
                                color: '#ffffff',
                                textTransform: 'none',
                                fontWeight: '700',
                                '&.Mui-disabled': {
                                    color: '#78716C',
                                    opacity: 1,
                                    pointerEvents: 'none',
                                },
                                '&.Mui-selected': {
                                    color: '#ffffff',
                                },
                                width: "20%"
                            }}
                            value="2" />
                        <Tab label="Done"
                            sx={{
                                color: '#ffffff',
                                textTransform: 'none',
                                fontWeight: '700',
                                '&.Mui-disabled': {
                                    color: '#78716C',
                                    opacity: 1,
                                    pointerEvents: 'none',
                                },
                                '&.Mui-selected': {
                                    color: '#ffffff',
                                },
                                width: "20%"
                            }}
                            value="3" />
                    </Tabs>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        }}
                    >
                        {this.state.tab == '1' && <TableContainer component={Paper}
                            sx={{ width: '100%', marginTop:2 }}
                        >
                            <Table
                                size="small"
                                sx={{
                                    maxWidth: "100%",
                                    backgroundColor: "#292524",
                                    '& .MuiTable-root': {
                                        color: "#ffffff",
                                    }
                                }} aria-label="simple table">
                                <TableHead>
                                    <TableRow >
                                        <TableCell sx={{ color: "#A8A29E", fontWeight: 700 }} align="center">Payment ID</TableCell>
                                        <TableCell sx={{ color: "#A8A29E", fontWeight: 700 }} align="center">Description</TableCell>
                                        <TableCell sx={{ color: "#A8A29E", fontWeight: 700 }} align="center">State</TableCell>
                                        <TableCell sx={{ color: "#A8A29E", fontWeight: 700 }} align="center">Date</TableCell>
                                        <TableCell sx={{ color: "#A8A29E", fontWeight: 700 }} align="center">Hour</TableCell>
                                        <TableCell sx={{ color: "#A8A29E", fontWeight: 700 }} align="center">Total</TableCell>

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {this.rows.map((row) => (
                                        <TableRow
                                            key={row.Payment_ID}
                                            sx={{
                                                '& td': { color: 'white' },
                                            }}
                                        >
                                            <TableCell width={10} align="center">{row.Payment_ID}</TableCell>
                                            <TableCell width={10} align="center" component="th" scope="row">
                                                <Chip label={row.Description} sx={{ color: "#ffffff", backgroundColor: "#55D9B4" }} />
                                            </TableCell>
                                            <TableCell width={20} align="center">{row.State}</TableCell>
                                            <TableCell width={20} align="center">{row.Hour}</TableCell>
                                            <TableCell width={20} align="center">{row.Date}</TableCell>
                                            <TableCell width={20} align="center">{row.Total}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>}
                        {this.state.tab == '2' && <Typography>Pending </Typography>}
                        {this.state.tab == '3' && <Typography> Done</Typography>}
                    </Box>
                </Box>
            </Box>

        );
    }
}

// Customizable Area Start
const webStyle = {
    inputLabel: {
        color: "#A8A29E",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
    select: {
        color: "#A8A29E",
        fontSize: "12px",
        paddingTop: "2px",
        paddingBottom: "2px",
        backgroundColor: "#57534E",
    },
    filterIcon: {
        width: '1rem',
        height: '1rem',
        fontSize: '1.2rem',
        backgroundColor: '#57534E',
        padding: '11px 11px',
        borderRadius: '4px',
        color: '#ffffff',
    },
    productContainer: {
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        width: "100%",
        backgroundColor: "white",
        marginTop: "20px",
    },
    productBox: {
        height: 250,
        width: "49%",
        marginRight: "5px",
        flexDirection: "column",
    },
    ImgContainer: {
        //marginBottom: 15,
        height: 150,
    },
    productImg: {
        width: "100%",
        height: "100%",
    },
    detailContent: {
        display: "flex",
        flexDirection: "column",
    },
};
// Customizable Area End
