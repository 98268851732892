import React, { Component } from 'react';
import { Box, InputLabel } from "@mui/material";
import Dropzone from 'react-dropzone';

interface FileType {
  name: string;
  size: number;
  preview?: any;
}

interface DropZoneState {
  files: FileType[];
}

export default class DropZone extends Component<{}, DropZoneState> {

  constructor(props: {}) {
    super(props);
    this.state = {
      files: []
    };
  }

  onDrop = (acceptedFiles: File[]) => {
    const files = acceptedFiles.map(file => ({
      name: file.name,
      size: file.size,
      preview: URL.createObjectURL(file)
    }));
    this.setState({ files });
  };


  render() {
    const { files } = this.state;

    const img = {
      display: 'block',
      width: '25px',
      height: '25px'
    };
    const icon = {
      width: '10px',
      height: '10px'
    };


    return (
      <Dropzone data-test-id={'dropzone'} onDrop={this.onDrop}>
        {({ getRootProps, getInputProps }) => (
          <> <section
            style={{
              border: '1px dashed white',
              padding: '34px',
              borderRadius: '8px',
              textAlign: "center"
            }}
          >
            <div {...getRootProps({ className: 'dropzone' })}>
              <input {...getInputProps()} />
              <InputLabel sx={{ color: "#A8A29E", fontWeight: "700", fontSize: "16px" }}>Add Images</InputLabel>
              <img
                style={{ width: 22, height: 22 }}
                src={require("../assets/img-icon.png")}
                alt="image"
              />
            </div>
          </section>
            <aside>
              {files.map(file => (
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} paddingY={1}>
                  <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'} gap={3} key={file.name}>
                    <img
                      src={file.preview}
                      style={img}
                      // onLoad={() => { URL.revokeObjectURL(file.preview) }}
                    />
                    <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "14px" }}>{file.name}</InputLabel>
                  </Box>
                  <img
                    src={require("../assets/close.svg")}
                    style={icon}
                  />
                </Box>
              ))}
            </aside>
          </>
        )}
      </Dropzone>
    );
  }
}

