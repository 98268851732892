import React from 'react'
import { Box, Button, TextField, Typography, InputLabel, Radio, RadioGroup, FormControlLabel } from "@mui/material";

import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DropZone from './DropZone';
import CatalogueCreateController from './CatalogueCreateController.web';

export default class CatalogueDish extends CatalogueCreateController {

  render() {

    return (
      <Box sx={{ display: "flex", flexDirection: "column", width: "100%", height: "100%" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "flex-start" }}>
          <Typography variant="h6" sx={{
            fontSize: '28px',
            fontWeight: 700,
          }}>
            <span style={{ color: "#C2B30A" }}>
              New
            </span>
            <span style={{ color: "#ffffff", paddingLeft: "6px" }}>
              Specialty
            </span>
          </Typography>

        </Box>
        <Box sx={{
          height: '90%',
          overflowY: 'auto',
          width: "100%"
        }}>
          <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: "20px", marginTop: 5 }}>
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'flex-start'}
              justifyContent={'center'}
              width={'65%'}
            >
              <Box sx={{ width: "100%" }}>
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Name of the Specialty</InputLabel>
                <OutlinedInput
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Enter Specialty"
                  data-testid="specialtyInput"
                  style={style.inputLabel}
                />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Description of the Specialty</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder=""
                  data-testid="specialtyInput"
                  multiline
                  variant="outlined"
                  sx={{
                    borderRadius: "8px",
                    fontSize: "14px",
                    backgroundColor: '#57534E',
                    color: '#FFFFFF',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#FFFFFF',
                        opacity: 1,
                      },
                    },
                  }}
                />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Images</InputLabel>
                <DropZone />
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />
                <InputLabel
                  sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}
                >
                  Ingredients
                </InputLabel>
                <TextField
                  fullWidth
                  variant="outlined"
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Add Ingredients"
                  data-testid="specialtyInput"
                  multiline
                  sx={{
                    borderRadius: "8px",
                    marginBottom: "15px",
                    fontSize: "14px",
                    backgroundColor: "#57534E",
                    color: "#FFFFFF",
                    "& .MuiInputBase-input": {
                      "&::placeholder": {
                        color: "#FFFFFF",
                        opacity: 1,
                      },
                    },
                  }}
                />
                <Box display={'flex'}
                  flexDirection={'column'}
                  justifyContent={'space-around'}
                  gap={1.5}>
                  <Button size='small'
                    sx={{
                      width: '16%',
                      fontSize: '14px', color: "#C2B30A", textTransform: "none",
                      borderColor: "#C2B30A",
                      '&:hover': {
                        borderColor: "#C2B30A",
                        backgroundColor: 'transparent'
                      },
                    }} variant="outlined" 
                    startIcon={<AddIcon sx={{ color: "#78716C !important" }} />}>
                    Add ingredient
                  </Button>

                  <div style={{
                    gap: '8px',
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                    {[{ key: "1", label: "Egg" },
                    { key: "2", label: "Chocolate chips" },
                    { key: "3", label: "Flour" }].map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip
                            style={{
                              borderRadius: '4px',
                              backgroundColor: "#78716C",
                              color: "#ffffff",
                              fontSize: '12px'
                            }} label={data.label}
                          />
                        </span>
                      );
                    })}
                  </div>

                </Box>
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "14px" }}>Add Category</InputLabel>

                <FormControl size="medium" sx={{ width: "100%" }}>
                  <Select
                    fullWidth
                    labelId="demo-select-small-label"
                    id="demo-select-small"
                    value={'12'}
                    label=""
                    sx={{
                      fontSize: '14px', color: "#C2B30A", textTransform: "none",
                      borderRadius: "8px",
                      '& .MuiSelect-icon': {
                        color: '#C2B30A',
                      },

                      '&.MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: "#292524",
                          backgroundColor: '#57534E',
                        },
                        '&:hover fieldset': {
                          borderColor: "#292524",
                          backgroundColor: '#57534E',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: "#292524",
                          backgroundColor: '#57534E',
                        },
                      },
                    }}

                  >
                    <MenuItem value={10}>Cake</MenuItem>
                    <MenuItem value={20}>Cupcakes</MenuItem>
                    <MenuItem value={30}>Cookies</MenuItem>
                    <MenuItem value={30}>Bread</MenuItem>

                  </Select>
                </FormControl>
                <div style={{
                  margin: "10px 0px",
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '8px',
                }}>
                  {[{ key: "1", label: "Cupcakes" }, { key: "2", label: "Cookies" }, { key: "3", label: "Bread" }].map((data) => {
                    return (
                      <span key={data.key}>
                        <Chip style={{ backgroundColor: "#78716C", color: "#ffffff", borderRadius: '4px', fontSize: '12px' }} label={data.label}
                        />
                      </span>
                    );
                  })}
                </div>
                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />
                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Allergens</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="Add allergen"
                  data-testid="specialtyInput"
                  multiline
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: '#57534E',
                    fontSize: "14px",
                    borderRadius: "8px",
                    color: '#FFFFFF',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#FFFFFF',
                        opacity: 1,
                      },
                    },
                  }}
                />
                <Box display={'flex'} flexDirection={'column'} justifyContent={'space-around'} gap={1.5}>
                  <Button size='small'
                    sx={{
                      width: '16%',
                      fontSize: '14px', color: "#C2B30A", textTransform: "none",
                      '&:hover': {
                        borderColor: "#C2B30A",
                        backgroundColor: 'transparent'
                      },
                      borderColor: "#C2B30A"
                    }} variant="outlined" startIcon={<AddIcon sx={{ color: "#78716C !important" }} />}>
                    Add Allergens
                  </Button>

                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                    {[{ key: "1", label: "Allergen #1" }, { key: "2", label: "Allergen #2" }, { key: "3", label: "Allergen #3" }].map((data) => {
                      return (
                        <span key={data.key}>
                          <Chip style={{ backgroundColor: "#78716C", color: "#ffffff", borderRadius: '4px', fontSize: '12px' }} label={data.label}
                          />
                        </span>
                      );
                    })}
                  </div>

                </Box>

                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />

                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Price</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="$ 00,00 usd"
                  data-testid="specialtyInput"
                  multiline
                  variant="outlined"
                  sx={{
                    marginBottom: "1px",
                    backgroundColor: '#57534E',
                    fontSize: "14px",
                    borderRadius: "8px",
                    color: '#FFFFFF',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#FFFFFF',
                        opacity: 1,
                      },
                    },
                  }}
                />


                <Divider sx={{
                  borderColor: "#57534E",
                  height: '2px',
                  marginY: 1
                }} />

                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Estimated time of completion</InputLabel>
                <TextField
                  fullWidth
                  id="outlined-adornment-specialty"
                  type="text"
                  placeholder="45 min"
                  data-testid="specialtyInput"
                  multiline
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: '#57534E',
                    fontSize: "14px",
                    borderRadius: "8px",
                    color: '#FFFFFF',
                    '& .MuiInputBase-input': {
                      '&::placeholder': {
                        color: '#FFFFFF',
                        opacity: 1,
                      },
                    },
                  }}
                />

                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Is this Specialty of the day?</InputLabel>
                <RadioGroup
                  row
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "80px" }}
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={'yes'}
                >
                  <FormControlLabel value="yes" control={<Radio sx={{
                    color: "#C2B30A",
                    '&.Mui-checked': {
                      color: "#C2B30A",
                    }
                  }} />} label="Yes" />
                  <FormControlLabel value="no" control={<Radio sx={{
                    color: "#C2B30A",
                    '&.Mui-checked': {
                      color: "#C2B30A",
                    }
                  }} />} label="No" />
                </RadioGroup>
                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    sx={{
                      padding: "16px 24px",
                      my: 1, backgroundColor: "#C2B30A",
                      color: "#ffffff",
                      textTransform: "none",
                      fontWeight: "700",
                      width: "343px",
                      height: '55px',
                      borderRadius: '8px',
                      '&:hover': {
                        backgroundColor: '#a59a00',
                      },
                      fontSize: '18px',
                    }}
                    fullWidth
                    variant="contained"
                    size="small"
                    data-test-id={"continueBtn"}
                    onClick={() => this.handleContinue()}
                  >Continue
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    )
  }
}

const style = {
  inputLabel: {
    borderRadius: 8,
    backgroundColor: "#57534E",
    color: "#A8A29E",
  },
};