import React from "react";
import {
  View,
  Text,
  Platform,
  StyleSheet,
  SafeAreaView,
  ScrollView,
  // Customizable Area Start
  // Customizable Area End
} from "react-native";
import { BlockComponent } from "../../framework/src/BlockComponent";
import AlertBlock from '../../blocks/alert/src/AlertBlock';
import CustomTextItem from "./CustomTextItem";
import NavigationBlock from "../../framework/src/Blocks/NavigationBlock";
import SingletonFactory from '../../framework/src/SingletonFactory';

import HomeScreenAdapter from '../../blocks/adapters/src/HomeScreenAdapter';
import InfoPageAdapter from '../../blocks/adapters/src/InfoPageAdapter';
import AlertPageAdapter from "../../blocks/adapters/src/AlertPageAdapter";

// Customizable Area Start

import { Sidebar, Menu, MenuItem, sidebarClasses} from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

import PrivacyPolicyAdapter from "../../blocks/adapters/src/PrivacyPolicyAdapter";
import TermsAndConditionAdapter from "../../blocks/adapters/src/TermsAndConditionAdapter";
import SplashScreenAdapter from "../../blocks/adapters/src/SplashScreenAdapter";
import EmailAccountLogInAdapter from "../../blocks/adapters/src/EmailAccountLogInAdapter";
import AnalyticsAdapter from "../../blocks/adapters/src/AnalyticsAdapter";
import EmailAccountSignUpAdapter from "../../blocks/adapters/src/EmailAccountSignUpAdapter";
import ForgotPasswordAdapter from "../../blocks/adapters/src/ForgotPasswordAdapter";
import MobilePhoneToOTPAdapter from "../../blocks/adapters/src/MobilePhoneToOTPAdapter";
import OtpToNewPasswordAdapter from "../../blocks/adapters/src/OtpToNewPasswordAdapter";
import SocialMediaAccountLoginAdapter from "../../blocks/adapters/src/SocialMediaAccountLoginAdapter";
import { Typography } from "@mui/material";

//Assembler generated adapters start
const emailAccountLogInAdapter = new EmailAccountLogInAdapter();
const analyticsAdapter = new AnalyticsAdapter();
const emailAccountSignUpAdapter = new EmailAccountSignUpAdapter();
const forgotPasswordAdapter = new ForgotPasswordAdapter();
const mobilePhoneToOTPAdapter = new MobilePhoneToOTPAdapter();
const otpToNewPasswordAdapter = new OtpToNewPasswordAdapter();
const socialMediaAccountLoginAdapter = new SocialMediaAccountLoginAdapter();

//Assembler generated adapters end



const privacyAdapter = new PrivacyPolicyAdapter();
const termAndConditionAdapter = new TermsAndConditionAdapter();
const splashScreenAdapter = new SplashScreenAdapter();
// Customizable Area End


const restAPIBlock = SingletonFactory.getRestBlockInstance();
const alertBlock = new AlertBlock();
const navigationBlock = new NavigationBlock();
const sessionBlock = SingletonFactory.getSessionBlockInstance();
const userAccountManagerBlock = SingletonFactory.getUserManagerInstance();
const homeScreenAdapter = new HomeScreenAdapter();
const infoPageAdapter = new InfoPageAdapter();
const alertPageAdapter = new AlertPageAdapter()

const instructions = Platform.select({
  // Customizable Area Start
  ios: "The iOS APP to rule them all!",
  android: "Now with Android AI",
  web: "Selector your adventure."
  // Customizable Area End
});

interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
interface S {
  collapsed: boolean;
}

interface SS { }

class HomeScreen extends BlockComponent<Props, S, SS> {

  static instance:HomeScreen;

  constructor(props: Props) {
    super(props);
    HomeScreen.instance = this; 
    
    this.state = {
      collapsed:false
    };
  }

  render() {
    const { navigation } = this.props;
    const _this = this;

    return (
      <div style={{ height:"100%", display:'flex' }}>
        <Sidebar
          collapsed={this.state.collapsed}
          rootStyles={{
            [`.${sidebarClasses.container}`]: {
              backgroundColor: '#292524',
              color: '#FFFFFF',
            },
            border: "1px solid #57534E",
            height: "100vh",
            fontWeight: "bold"
          }}
        >
          <div style={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
            <img src={require("./logo.svg")} />
            {!this.state.collapsed && <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px' }}>
              AMATEUR BAKER
            </Typography>}
          </div>
          <Menu
            menuItemStyles={{
              button: ({ level, active, disabled }) => {
                return {
                  borderRadius: "8px",
                  padding: "0px 8px",
                  margin: "10px 14px",
                  "&:hover": {
                    backgroundColor: "#57534E",
                    color: "#C2B30A",
                    fontWeight: "bold"
                  },
                  cursor: 'pointer'
                };
              },
            }}
          >
            <MenuItem icon={<img src={require("./dashboard.svg")} />} component={<Link to="/dashboard" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Dashboard</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./catalogue.svg")} />} component={<Link to="/catalogue" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Catalogue</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./orderhistory.svg")} />} component={<Link to="/order-history" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Orders History</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./payments.svg")} />} component={<Link to="/payments" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Payments</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./notifications.svg")} />} component={<Link to="/notifications" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Notifications</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./summary.svg")} />} component={<Link to="/summary" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Summary Dashboard</Typography>
            </MenuItem>
            <hr style={{ color: "#57534E" }} />
            <MenuItem icon={<img src={require("./legal.svg")} />} component={<Link to="/legal" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Legal</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./settings.svg")} />} component={<Link to="/settings" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Settings</Typography>
            </MenuItem>
            <MenuItem icon={<img src={require("./support.svg")} />} component={<Link to="/support" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>Support</Typography>
            </MenuItem>
            <MenuItem style={{ border: "1px solid #57534E", padding: "0px" }} icon={<img src={require("./logout.svg")} />} component={<Link to="/" />}>
              <Typography variant='body2' style={{ fontWeight: 700, fontSize: '16px' }}>{!this.state.collapsed && "Log Out"}</Typography>
            </MenuItem>
          </Menu>
        </Sidebar>
        <img onClick={() => this.setState({ collapsed: !this.state.collapsed })}
          style={{
            position: "relative",
            right: this.state.collapsed ? "10%" :"5%",
            top:"45%",
            zIndex: "99",
            height:"25px",
            width:"25px"
          }}
          src={this.state.collapsed ? require("./right.svg") : require("./left.svg")} />
      </div>
    );
  }
}
// Customizable Area End

// Customizable Area Start
const styles = StyleSheet.create({
  bgBlack:{
    backgroundColor: "#292524",
    color:"#FFFFFF"
  },

  scrollView: {
    flexGrow: 1,
    height: Platform.OS === "web" ? '100vh' : 'auto',
    backgroundColor: "#F5FCFF"
  },
  container: {
    flex: 1
  },
  welcome: {
    fontSize: 20,
    textAlign: "center",
    fontWeight: "bold",
    color: "white"
  },
  instructions: {
    textAlign: "center",
    color: "#6200EE",
    marginBottom: 5,
    fontWeight: 'bold',
    fontSize: 16,

    padding: 10
  },
  button: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  header: {
    backgroundColor: '#6200EE',
    padding: 15,
    color: 'white',
    fontSize: 16,
    fontWeight: 'bold'
  },
  item: {
    backgroundColor: '#00000000',
    padding: 18,
    color: '#6200EE',
    fontSize: 16,
    fontWeight: 'normal'
  }
});
// Customizable Area End
export default HomeScreen;