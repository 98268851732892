import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { AlertColor } from "@mui/material";
interface FormValues {
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
    password: string;
    address: string;
    state: string;
    zipcode: string;
    agreeToTerms: boolean;
}

interface TouchedFields {
    firstName: boolean;
    lastName: boolean;
    phoneNumber: boolean;
    email: boolean;
    password: boolean;
    address?: boolean;
    state?: boolean;
    zipcode?: boolean;
    agreeToTerms?: boolean;
}

import * as Yup from "yup";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    formValues: FormValues;
    countryCodeSelected: string,
    showPassword: boolean,
    formErrors: Partial<FormValues>;
    touchedFields: TouchedFields;
    data: Object,
    step: number;
    errorMessages: string[];
    showToast: boolean,
    toastMessage: string,
    toastSeverity: AlertColor;
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    id: any;
    // Customizable Area End
}

export default class EmailAccountUserRegistrationController extends BlockComponent<
    Props,
    S,
    SS
> {
    // Customizable Area Start
    apiEmailSignupCallId: any = "";
    validationApiCallId: string = "";
    labelTitle: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.CountryCodeMessage),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.ReciveUserCredentials),
        ];

        this.state = {
            formValues: {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                email: "",
                password: "",
                address: "",
                state: "",
                zipcode: "",
                agreeToTerms: false,
            },
            countryCodeSelected: "+91",
            showPassword: false,
            formErrors: {},
            touchedFields: {
                firstName: false,
                lastName: false,
                phoneNumber: false,
                email: false,
                password: false,
                address: false,
                state: false,
                zipcode: false,
                agreeToTerms: false,
            },
            data: {},
            step: 1,
            errorMessages: [],
            showToast: false,
            toastMessage: '',
            toastSeverity: 'success' as AlertColor,
        };

        this.labelTitle = configJSON.labelTitle;
        // Customizable Area End

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    // Customizable Area Start
    async componentDidMount() {
        this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area End
    // Customizable Area Start
    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword })
    };
    // Customizable Area End

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) !== message.id) return;

        const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
        const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

        if (!apiRequestCallId) return;

        if (apiRequestCallId === this.apiEmailSignupCallId) {
            this.handleEmailSignupResponse(responseJson);
        }
        // Customizable Area End
    }
    // Customizable Area Start

    handleEmailSignupResponse(responseJson: any) {
        if (responseJson?.meta?.token) {
            this.onSignupSuccess(responseJson);
        } else {
            this.onSignupFail(responseJson);
        }
    }

    onSignupSuccess(responseJson: any) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveSignupUserData(responseJson);
        this.handleShowToast("Registration successful!", "success");
    }

    onSignupFail(responseJson: any) {
        const errorMessage = responseJson.errors?.[0] || "Something went wrong";
        this.handleShowToast(errorMessage, "error");
        this.parseApiErrorResponse(responseJson);
        this.sendSignupFailMessage();
    }
    sendSignupFailMessage() {
        const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
        this.send(msg);
    }

    saveSignupUserData(responseJson: any) {
        if (responseJson && responseJson.meta && responseJson.meta.token) {
            const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));
    
            msg.addData(
                getName(MessageEnum.SessionResponseData),
                JSON.stringify(responseJson)
            );
            msg.addData(
                getName(MessageEnum.SessionResponseToken),
                responseJson.meta.token
            );
    
            this.send(msg);
    
            // Reset the form state after successful submission
            this.setState({
                formValues: {
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    password: "",
                    address: "",
                    state: "",
                    zipcode: "",
                    agreeToTerms: false
                },
                touchedFields: {
                    firstName: false,
                    lastName: false,
                    phoneNumber: false,
                    email: false,
                    password: false,
                    address: false,
                    state: false,
                    zipcode: false,
                    agreeToTerms: false
                },
                formErrors: {},
                step: 1,
            });
        }
    }
    

    doEmailSignup = (values: FormValues): boolean => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const attrs = {
            first_name: values.firstName,
            password: values.password,
            email: values.email,
            last_name: values.lastName,
            address: values.address,
            full_phone_number: `${this.state.countryCodeSelected}${values.phoneNumber}`,
            zipcode: values.zipcode,
            state: values.state
        };

        const data = {
            type: "email_account",
            attributes: {
                ...attrs,
                user_type: "customer"
            },
        };

        const httpBody = {
            data: data,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.apiEmailSignupCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.SignupAPiEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeAddDetail
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);

        return true;
    }

    handleCountryCodeChange = (event: any) => {
        this.setState({
            countryCodeSelected: event.target.value,
        });
    }

    
    handleShowToast = (message: string, severity: AlertColor) => {
        this.setState({ showToast: true, toastMessage: message, toastSeverity: severity });
    }


    handleCloseToast = () => {
        this.setState({ showToast: false });
    }

    handleChange = (field: keyof FormValues) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        this.setState(
            prevState => ({
                formValues: {
                    ...prevState.formValues,
                    [field]: value,
                },
                touchedFields: {
                    ...prevState.touchedFields,
                    [field]: true,
                },
            }),
            () => {
                this.validateForm(this.state.step);
            }
        );
    };
    

    handleNext = async () => {
        const allTouchedFields = {
            firstName: true,
            lastName: true,
            phoneNumber: true,
            email: true,
            password: true,
        };
    
        this.setState({ touchedFields: allTouchedFields }, async () => {
            const isValid = await this.validateForm(this.state.step);
            
            if (isValid) {
                this.setState({ step: 2 });
            }
        });
    };    

    step1Schema = Yup.object().shape({
        firstName: Yup.string().required("First Name is required"),
        lastName: Yup.string().required("Last Name is required"),
        phoneNumber: Yup.string().required("Phone Number is required"),
        email: Yup.string().email("Invalid email format").required("Email is required"),
        password: Yup.string().required("Password is required").min(5, "Password must be minimum 5 characters"),
    });

    step2Schema = Yup.object().shape({
        address: Yup.string().required("Address is required"),
        state: Yup.string().required("State is required"),
        zipcode: Yup.string().required("Zipcode is required"),
        agreeToTerms: Yup.boolean().oneOf([true], "Accept the terms and conditions"),
    });

    validateForm = async (step: number) => {
        let schema = step === 1 ? this.step1Schema : this.step2Schema;
        try {
            await schema.validate(this.state.formValues, { abortEarly: false });
            this.setState({ formErrors: {} });
            return true;
        } catch (err) {
            const error = err as Yup.ValidationError;
            const errors = error.inner.reduce((acc: any, err: Yup.ValidationError) => {
                acc[err.path] = err.message;
                return acc;
            }, {});
            this.setState({ formErrors: errors });
            return false;
        }
    };
    
    handleBack = () => {
        this.setState({ step: this.state.step - 1 });
    };

    handleSubmit = async () => {
        const isValid = await this.validateForm(this.state.step);
        if (isValid) {
            this.doEmailSignup(this.state.formValues);
        }
    };

    handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        this.setState(prevState => ({
            formValues: {
                ...prevState.formValues,
                agreeToTerms: checked,
            },
            touchedFields: {
                ...prevState.touchedFields,
                agreeToTerms: true,
            },
        }), () => {
            // Trigger validation after setting the state
            this.validateForm(this.state.step);
        });
    };
    

    // Customizable Area End
}
