import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");

export interface Props {
}

interface S {
    showPassword:boolean,
    open:boolean
}

interface SS {
}

export default class DeleteAccountController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.state = {
            showPassword: false,
            open:false
          };
    }

    handleClose = () => {
        this.setState({
            open: false
        })
    }

    handleClickShowPassword = () => {
        this.setState({showPassword:!this.state.showPassword})
      };

    deleteAcc = (values: any) => {
        
    };

}
