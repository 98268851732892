import React from "react";
import { Box, Button, Typography } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Grid from '@mui/material/Grid';
import AcceptOrDeliverModalController, { Props } from "./AcceptOrDeliverModalController.web";


export default class AcceptOrDeliverModal extends AcceptOrDeliverModalController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Dialog
                sx={{
                    padding: '40px 16px',
                    borderRadius: '8px',
                    '& .MuiDialog-paper': {
                        backgroundColor: '#000000',
                    },
                }}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    <Grid container>
                        <Grid item xs={12}  >
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>

                                <Typography variant="h2" sx={{
                                    fontSize: '18px',
                                    fontWeight: 700,
                                    textAlign: "center"
                                }}>
                                    <span style={{ color: "#C2B30A" }}>
                                        Order&nbsp;
                                    </span>
                                    <span style={{ color: "#ffffff" }}>
                                        #1654194
                                    </span>
                                </Typography>
                                <img onClick={this.props.handleClose} src={require("../assets/close.svg")} />
                            </Box>
                        </Grid>
                        <Grid item xs={12}>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "16px 8px 5px 0px" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "12px", color: "#D6D3D1", fontWeight: 400 }} variant="subtitle1" color="text.secondary" component="div">
                                        Client: &nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                        James Hetfield
                                    </Typography>
                                </Box>
                                <Typography sx={{ fontSize: "12px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                    23:59:59
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", alignItems: 'center', padding: "5px 8px 5px 0px" }}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: "space-around", alignItems: 'center' }}>
                                    <Typography sx={{ fontSize: "12px", color: "#D6D3D1", fontWeight: 400 }} variant="subtitle1" color="text.secondary" component="div">
                                        Client time to pick up: &nbsp;
                                    </Typography>
                                    <Typography sx={{ fontSize: "14px", color: "#ffffff", fontWeight: 400 }} variant="subtitle1" component="div">
                                        13:00 pm
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <Divider sx={{ borderColor: "#57534E" }} />
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">

                        <Grid container>
                            <Grid item xs={12}>
                                <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                    1x
                                </Typography>
                            </Grid>
                            <Grid item xs={11}>
                                <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                    Cake
                                </Typography>
                            </Grid>
                            <Grid item xs={1}>
                                <Typography color={'#ffffff'} sx={{ fontSize: "16px", fontWeight: "700" }} gutterBottom variant="subtitle1" component="div">
                                    $20,00
                                </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <List sx={{ listStyleType: 'disc', paddingLeft: '25px', paddingTop: "0px" }}>
                                    <ListItem sx={{ display: 'list-item', color: '#ffffff', padding: '3px 2px', fontSize: "14px", fontWeight: "400" }}>
                                        Extra Whipped Cream
                                    </ListItem>
                                    <ListItem sx={{ display: 'list-item', color: '#ffffff', padding: '3px 2px', fontSize: "14px", fontWeight: "400" }}>
                                        Extra Chocolate Chips
                                    </ListItem>
                                </List>
                                <Divider sx={{ borderColor: "#57534E", marginBottom: "15px" }} />
                            </Grid>
                            <Grid item xs={9}>
                            </Grid>
                            <Grid item xs={3}>
                                <Box
                                    display="flex"
                                    alignItems="center"
                                    justifyContent="flex-end"
                                    sx={{ width: '100%' }}
                                >
                                    <Typography sx={{ fontSize: "16px", color: "#A8A29E", fontWeight: 700 }} variant="subtitle1" color="text.secondary" component="div">
                                        Total: &nbsp;
                                    </Typography>
                                    <Typography color={'#ffffff'} sx={{ fontSize: "18px", fontWeight: 700 }} gutterBottom variant="subtitle1" component="div">
                                        $40,00
                                    </Typography>
                                </Box>
                            </Grid>
                        </Grid>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        padding: '16px',
                        gap: "10px"
                    }}
                >
                    <Button
                        onClick={
                            this.props.type == 'accept' ? this.props.openDeclineModal : this.props.handleClose
                        }
                        sx={{
                            width: '343px',
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            color: '#ffffff',
                            backgroundColor: '#C2B30A',
                            '&:hover': {
                                backgroundColor: '#a59a00',
                            },
                            fontSize: '16px',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                        data-testid="opn-store3"

                    >{this.props.type == 'accept' ? "Decline order" : "Cancel Order"}</Button>
                    <Button
                        onClick={this.props.handleClose}
                        sx={{
                            width: '343px',
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            border: "1px solid #ffffff",
                            color: '#ffffff',
                            backgroundColor: 'transparent',
                            fontSize: '16px',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                    >
                        {this.props.type == 'accept' ? "Accept order" : "Ready to deliver"}
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}
