
import React from "react";
import { Button, Typography } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import OpenBakeryModalController, { Props } from "./OpenBakeryModalController.web";

export default class OpenBakeryModal extends OpenBakeryModalController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Dialog
                sx={{
                    borderRadius: '8px',
                    '& .MuiDialog-paper': {
                        backgroundColor: '#000000',
                    },
                    padding: '40px 16px',
                }}
                open={this.props.open}
                onClose={this.props.handleClose}
                aria-describedby="open-bakery-description"
                aria-labelledby="open-bakery-title"
            >
                <DialogTitle id="open-bakery-title">
                    <Typography variant="h6"
                        sx={{
                            textAlign: "center",
                            fontSize: '32px',
                            fontWeight: 700,
                        }}> <span style={{ color: "#ffffff" }}>
                            Are you sure&nbsp;
                        </span><span style={{ color: "#C2B30A" }}>
                            to Open
                        </span> <span style={{ color: "#ffffff" }}>
                            ?
                        </span>
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="open-bakery-description">
                        <Typography sx={{
                            fontSize: '14px',
                            fontWeight: 400,
                            color: "#ffffff",
                            textAlign: "center"
                        }} variant="body1">
                            if you open that means that you are ready to receive orders from now.
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <Button
                        onClick={this.props.openStore}
                        sx={{
                            width: '343px',
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            color: '#ffffff',
                            backgroundColor: '#C2B30A',
                            '&:hover': {
                                backgroundColor: '#a59a00',
                            },
                            fontSize: '18px',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                        data-testid="opn-store1"

                    >Yes, Open</Button>
                    <Button
                        onClick={this.props.handleClose}
                        sx={{
                            width: '343px',
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            border: "1px solid #ffffff",
                            color: '#ffffff',
                            backgroundColor: 'transparent',
                            fontSize: '18px',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                    >No, Keep Close</Button>
                </DialogActions>
            </Dialog>
        );
    }
}