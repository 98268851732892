import { BlockComponent } from "../../../framework/src/BlockComponent";
export const configJSON = require("./config");


export interface Props {
}

interface S {
    otpRefs: any;
    step: string;
}

interface SS {
}

export default class ChangePasswordController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.state = {
            step: "one",
            otpRefs:[]
        }
    }

    changePassword = (values: any) => {
    };

}