import React from "react";
import ChangePasswordController, {
    Props,
} from "./ChangePasswordController.web";
import OutlinedInput from '@mui/material/OutlinedInput';

import { Formik } from "formik";
import * as Yup from "yup";
import { Box, Button, InputLabel } from "@mui/material";
// import PasswordChecklist from "react-password-checklist"

export default class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Box sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                justifyContent: "center",
                alignItems: "center",
                marginTop: 5
            }}>
                <Formik
                    initialValues={{ currentPassword: "", newPassword: "", confirmPassword: "" }}
                    validationSchema={
                        Yup.object().shape({
                            currentPassword: Yup.string().email().required("Current Password is required"),
                            newPassword: Yup.string().required("New Password is required"),
                            confirmPassword: Yup.string()
                                .required("Confirm Password is required")
                                .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
                        })


                    }
                    validateOnMount={true}
                    validateOnChange={true}
                    onSubmit={(values) => {
                        this.changePassword(values);
                    }}
                    data-testid="passForm"
                >
                    {({
                        handleChange,
                        handleSubmit,
                        errors,
                        touched,
                        values
                    }) => (
                        <>
                            <Box
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'flex-start'}
                                justifyContent={'center'}
                                width={'65%'}
                            >
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Current Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    onChange={handleChange("currentPassword")}
                                    type={'text'}
                                    placeholder={"Current Password"}
                                    value={values.currentPassword}
                                    data-test-id='currentPass'
                                />
                                {errors.currentPassword && touched.currentPassword ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.currentPassword}
                                    </span>
                                ) : null}
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Current Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"New Password"}
                                    value={values.newPassword}
                                    onChange={handleChange("newPassword")}
                                    data-test-id='newPass'
                                />
                                {errors.newPassword && touched.newPassword ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.newPassword}
                                    </span>
                                ) : null}
                                <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Current Password</InputLabel>
                                <OutlinedInput
                                    fullWidth
                                    style={webStyles.inputLabel}
                                    id="outlined-adornment-email"
                                    type={'text'}
                                    placeholder={"Confirm Password"}
                                    value={values.confirmPassword}
                                    onChange={handleChange("confirmPassword")}
                                    data-test-id='confirmPass'
                                />
                                {errors.confirmPassword && touched.confirmPassword ? (
                                    <span style={webStyles.errorStyle}>
                                        {errors.confirmPassword}
                                    </span>
                                ) : null}

                                {/* <PasswordChecklist
                                    rules={["minLength", "specialChar", "number", "capital", "match"]}
                                    minLength={8}
                                    maxLength={8}
                                    datatest-id={'checklist'}
                                    value={values.newPassword}
                                    valueAgain={values.confirmPassword}
                                    onChange={() => { }}
                                    validTextColor="#ffffff"
                                    invalidTextColor="#ffffff"
                                    rtl={true}
                                    iconSize={14}
                                    style={{ fontSize: 12, margin: 20, marginTop: 0}}
                                    iconComponents={{
                                        ValidIcon: <img
                                            src={require('../assets/valid.png')}
                                            style={webStyles.icon}
                                        ></img>, InvalidIcon: <img
                                            src={require('../assets/invalid.png')}
                                            style={webStyles.icon}
                                        ></img>
                                    }}
                                    messages={{
                                        minLength: "Password must be minimum 8 chracters long",
                                        specialChar: "Password must have special chracters",
                                        number: "Password must have numbers",
                                        capital: "Password must have capital letters",
                                        match: "New Password must match with Confirm Password",
                                    }}
                                /> */}
                            </Box>
                            <Button
                                data-testThenid={"changePassBtn"}
                                style={webStyles.button}
                                color="secondary"
                                variant="outlined"
                                onClick={() => handleSubmit()}
                            >
                                Change Password
                            </Button>
                        </>
                    )}
                </Formik>
            </Box>
        )
    }
}

const webStyles = {
    icon: {
        height: 14,
        width: 14,
        margin: 5,
        marginTop: 7
    },
    inputLabel: {
        color: "#A8A29E",
        borderRadius: 8,
        backgroundColor: "#57534E",
        paddingBottom: "3px",
        marginBottom:"3px"
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        borderRadius: 8,
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        paddingBottom: "30px",
        backgroundColor: "#ffff",
        width: "50%",
        fontFamily: "Roboto, sans-serif"
    },
    titleText: {
        fontSize: 30,
        paddingVertical: 10,
        fontWeight: "600",
    },
    bodyText: {
        fontSize: 20,
        paddingVertical: 15,
        fontWeight: "400",
    },
    inputStyle: {
        width: "100%",
        height: "100px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
    },
    buttonStyle: {
        width: "100%",
        height: "45px",
        marginTop: "40px",
        backgroundColor: "rgb(98, 0, 238)",
    },
};