import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
}

interface S {
    token: string;
    tab: string;
}

interface SS {
    id: any;
}

export default class PaymentsController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    constructor(props: Props) {
        super(props);

        this.state = {
            token: "",
            tab: "1"
        };

    }

    handleChange = (e: any, newValue: any) => {
        this.setState({ tab: newValue });
    };

    handleSelect = (event: any) => {
        this.setState({ token: event.target.value });
    };

}
