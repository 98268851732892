import React from "react";

// Customizable Area Start
import {
    StyleSheet,
} from "react-native";
import { Typography, Button, Link, InputLabel, FormControl, MenuItem, Select, styled, Snackbar, Alert } from "@mui/material";
import * as Yup from "yup";
import IconButton from '@mui/material/IconButton';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

interface FormFieldProps {
    label: string;
    id: string;
    type: string;
    placeholder: string;
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    error?: string;
    touched?: boolean;
    dataTestId: string;
}

const CountryCodeSelect = styled(Select)({
    color: "#A8A29E",
    padding: "5px 15px",
    borderRadius: 8,
    backgroundColor: "#57534E",
});
// Customizable Area End

import EmailAccountUserRegistrationController, {
    Props
} from "./EmailAccountUserRegistrationController.web";

export default class EmailAccountRegistration extends EmailAccountUserRegistrationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    getValidationSchema = (step: number) => {
        if (step === 1) {
            return this.step1Schema;
        } else if (step === 2) {
            return this.step2Schema;
        }
        return Yup.object().shape({});
    };

    FormField = ({ label, id, type, placeholder, value, onChange, error, touched, dataTestId }: FormFieldProps) => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>{label}</InputLabel>
            <OutlinedInput
                fullWidth
                style={styles.inputLabel}
                id={id}
                type={type}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                data-test-id={dataTestId}
            />
            {error && touched && <span style={styles.errorStyle}>{error}</span>}
        </div>
    );

    PhoneNumberField = () => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Phone Number</InputLabel>
            <div style={{ display: "flex", gap: "10px" }}>
                <FormControl style={styles.inputLabel}>
                    <CountryCodeSelect
                        name="countryCodeSelected"
                        value={this.state.countryCodeSelected}
                        onChange={this.handleCountryCodeChange}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Country code' }}
                        data-test-id="phoneNumberCountryCode"
                    >
                        <MenuItem value="+1">+123</MenuItem>
                        <MenuItem value="+44">+44</MenuItem>
                        <MenuItem value="+91">+91</MenuItem>
                    </CountryCodeSelect>
                </FormControl>
                <OutlinedInput
                    fullWidth
                    style={styles.inputLabel}
                    id="outlined-adornment-phoneNumber"
                    type="text"
                    placeholder="Your Phone Number"
                    value={this.state.formValues.phoneNumber}
                    onChange={this.handleChange("phoneNumber")}
                    data-test-id='phoneNumberInput'
                />
            </div>
            {this.state.formErrors.phoneNumber && this.state.touchedFields.phoneNumber && (
                <span style={styles.errorStyle}>{this.state.formErrors.phoneNumber}</span>
            )}
        </div>
    );

    PasswordField = () => (
        <div style={styles.inputWrapper}>
            <InputLabel sx={{ color: "#ffffff", fontWeight: "700", fontSize: "16px" }}>Password</InputLabel>
            <OutlinedInput
                data-test-id='passwordInput'
                fullWidth
                style={styles.inputLabel}
                id="outlined-adornment-password"
                type={this.state.showPassword ? 'text' : 'password'}
                placeholder={"Password"}
                value={this.state.formValues.password}
                onChange={this.handleChange('password')}
                endAdornment={
                    <InputAdornment position="end">
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={() => this.handleClickShowPassword()}
                            edge="end"
                            data-test-id="passIcon"
                        >
                            {this.state.showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {this.state.formErrors.password && this.state.touchedFields.password ? (
                <span style={styles.errorStyle}>
                    {this.state.formErrors.password}
                </span>
            ) : null}
        </div>
    );

    renderStepOne = () => (
        <div style={{ width: "100%" }}>
            {this.FormField({
                label: "First Name",
                id: "outlined-adornment-firstName",
                type: "text",
                placeholder: "Your First Name",
                value: this.state.formValues.firstName,
                onChange: this.handleChange("firstName"),
                error: this.state.formErrors.firstName,
                touched: !!this.state.touchedFields.firstName,
                dataTestId: "firstNameInput",
            })}
            {this.FormField({
                label: "Last Name",
                id: "outlined-adornment-lastName",
                type: "text",
                placeholder: "Your Last Name",
                value: this.state.formValues.lastName,
                onChange: this.handleChange("lastName"),
                error: this.state.formErrors.lastName,
                touched: !!this.state.touchedFields.lastName,
                dataTestId: "lastNameInput",
            })}
            {this.PhoneNumberField()}
            {this.FormField({
                label: "Email",
                id: "outlined-adornment-email",
                type: "text",
                placeholder: "Email",
                value: this.state.formValues.email,
                onChange: this.handleChange("email"),
                error: this.state.formErrors.email,
                touched: !!this.state.touchedFields.email,
                dataTestId: "emailInput",
            })}
            {this.PasswordField()}
            <div style={styles.buttonsWrapper}>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    data-test-id="btnNext"
                    onClick={this.handleNext}
                    style={styles.button}
                >
                    Next
                </Button>
            </div>
        </div>
    );

    renderStepTwo = () => (
        <div style={{ width: "100%" }}>
            {this.FormField({
                label: "Address",
                id: "outlined-adornment-address",
                type: "text",
                placeholder: "Your Address",
                value: this.state.formValues.address || "",
                onChange: this.handleChange("address"),
                error: this.state.formErrors.address,
                touched: !!this.state.touchedFields.address,
                dataTestId: "addressInput",
            })}
            <div style={{ display: "flex", width: "100%" }}>
                <div style={{ width: "50%", marginRight: "15px" }}>

                    {this.FormField({
                        label: "State",
                        id: "outlined-adornment-state",
                        type: "text",
                        placeholder: "State",
                        value: this.state.formValues.state || "",
                        onChange: this.handleChange("state"),
                        error: this.state.formErrors.state,
                        touched: !!this.state.touchedFields.state,
                        dataTestId: "stateInput",
                    })}
                </div>
                <div style={{ width: "50%" }}>
                    {this.FormField({
                        label: "Zipcode",
                        id: "outlined-adornment-zipcode",
                        type: "text",
                        placeholder: "Zipcode",
                        value: this.state.formValues.zipcode || "",
                        onChange: this.handleChange("zipcode"),
                        error: this.state.formErrors.zipcode,
                        touched: !!this.state.touchedFields.zipcode,
                        dataTestId: "zipcodeInput",
                    })}

                </div>


            </div>
            <div style={styles.checkboxWrapper}>
                <label>
                    <input
                        type="checkbox"
                        name="agreeToTerms"
                        checked={this.state.formValues.agreeToTerms || false}
                        onChange={this.handleCheckboxChange}
                        data-test-id="termsCheckbox"
                    />
                    <span style={styles.checkboxLabel}>
                        I agree with
                        <Link variant="caption" style={styles.forgotPassword} color="inherit">
                            Terms and Conditions
                        </Link>
                        <span style={{ margin: "0px 5px" }}>and</span>
                        <Link variant="caption" style={styles.forgotPassword} color="inherit">
                            Privacy Policy
                        </Link>
                    </span>
                </label>
                {this.state.formErrors.agreeToTerms && this.state.touchedFields.agreeToTerms ? (
                    <span style={styles.errorStyle}>
                        {this.state.formErrors.agreeToTerms}
                    </span>
                ) : null}
            </div>
            <div style={styles.buttonsWrapper}>
                <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    data-test-id="btnSubmit"
                    onClick={this.handleSubmit}
                    style={styles.button}
                >
                    Submit
                </Button>
            </div>
        </div>
    );


    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <div style={styles.main}>
                <Snackbar
                    open={this.state.showToast}
                    autoHideDuration={6000}
                    onClose={this.handleCloseToast}
                >
                    <Alert onClose={this.handleCloseToast} severity={this.state.toastSeverity}>
                        {this.state.toastMessage}
                    </Alert>
                </Snackbar>

                <div style={styles.wrapper}>
                    <img style={styles.banner} src={require("../../email-account-login/assets/banner.png")} />
                    <div style={{
                        content: '',
                        position: "absolute",
                        top: 0,
                        left: 0,
                        right: 0,
                        bottom: 0,
                        background: "linear-gradient(to right, rgba(0,0,0,0) 94%, rgb(0, 0, 0) 100%)",
                        zIndex: 1
                    }}></div>
                </div>
                <div style={styles.body}>
                    <div style={{ display: 'flex', flexDirection: "column" as const, alignItems: 'center', marginBottom: '24px', marginTop: '20px' }}>
                        <img width={71} height={50} src={require("../../../components/src/logo.svg")} />
                        <Typography variant="h5" style={{ color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", marginTop: '8px', letterSpacing: "3px" }}>
                            AMATEUR BAKER
                        </Typography>
                        <Typography variant="caption" style={{ color: "#C2B30A", fontWeight: "200", textTransform: "uppercase", marginTop: '4px', letterSpacing: "3px" }}>
                            from our oven to your table
                        </Typography>
                        <Typography variant="h4" style={{ marginTop: "20px", color: "#C2B30A", fontWeight: "700", textTransform: "uppercase", letterSpacing: "3px" }}>
                            User Sign up
                        </Typography>
                        <div style={styles.ProgressIndicator}>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "white" }}>
                                <div style={{
                                    ...styles.Step,
                                    ...(this.state.step >= 1 ? styles.activeStep : {}),
                                }}></div>
                                <div style={{ fontSize: "15px" }}>1</div>
                                <div style={{ fontSize: "10px" }}>Personal Information</div>
                            </div>
                            <div style={styles.Line}></div>
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", color: "white" }}>
                                <div style={{
                                    ...styles.Step,
                                    ...(this.state.step >= 2 ? styles.activeStep : {}),
                                }}></div>
                                <div style={{ fontSize: "15px" }}>2</div>
                                <div style={{ fontSize: "10px" }}>Address Information</div>
                            </div>

                        </div>
                    </div>
                    <div data-test-id="signupForm" style={{ width: "50%" }}>
                        {this.state.step === 1 && this.renderStepOne()}
                        {this.state.step === 2 && this.renderStepTwo()}
                    </div>
                </div>
            </div>
            // Customizable Area End
        );
    }
}

const styles = StyleSheet.create({
    // Customizable Area Start
    main: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#000000",
        fontSize: 25,
    },
    checkboxWrapper: {
        marginTop: '10px',
        marginBottom: '20px',
        display: 'flex',
        flexDirection: "column",
        alignItems: 'center',
    },
    checkboxLabel: {
        color: '#ffffff',
        fontSize: 14,
        marginLeft: '10px',
    },
    activeStep: {
        backgroundColor: '#f5c800',
    },

    Step: {
        width: '15px',
        height: '15px',
        backgroundColor: '#444',
        borderRadius: 50,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: 1,
        color: '#fff',
    },

    Line: {
        width: '50px',
        height: '2px',
        backgroundColor: '#444',
        margin: '0 10px',
    },

    ProgressIndicator: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
    },
    wrapper: {
        flex: 1,
        position: "relative",
    },
    banner: {
        width: "100%", height: "100vh"
    },
    gradient: {
        width: '100%',
        height: '100%',
    },
    body: {
        flex: 1,
        display: "flex",
        flexDirection: "column" as const,
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        width: "40%",
    },
    eyeIcon: {
        height: "25px",
        width: "25px"
    },
    input: {
        borderRadius: 8,
        backgroundColor: "#57534E",
        borderColor: "#000000",
    },
    inputLabel: {
        color: "#A8A29E",
        padding: "5px 15px",
        borderRadius: 8,
        backgroundColor: "#57534E",
    },
    textFont: {
        fontFamily: "Cerebri Sans",
        color: "#55D9B4",
        fontSize: 32,
        fontWeight: "700",
        lineHeight: 3,
        textAlign: "left",
    },
    forgotPassLabel: { display: "flex", justifyContent: "center", alignItems: "center" },
    formWrapper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column" as const,
        width: "100%",
        paddingTop: "20px"
    },
    inputWrapper: {
        flex: 1,
        margin: "20px",
        width: "100%"
    },
    paddingBottom: {
        paddingBottom: "0px"
    },
    removeBackground: {
        backgroundColor: "transparent",
        height: "10px"
    },
    checkBoxText: {
        fontSize: 18,
        color: "#6200EE",
        fontWeight: "bold",
    },
    buttonsWrapper: {
        flex: 1,
        padding: "20px",
        paddingTop: "0px",
        paddingBottom: "0px",
        width: "100%"
    },
    notHaveAccount: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#FFFFFF"
    },
    logo: {
        height: 80,
        width: 80,
        marginTop: "50px",
        marginBottom: "25px",
    },
    backArrow: {
        height: 30,
        width: 30,
    },
    arrowWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "20px"
    },
    button: {
        backgroundColor: "#C2B30A",
        color: "#ffffff",
        padding: "15px",
        fontWeight: "700"
    },
    forgotPassword: {
        fontWeight: "700",
        fontSize: 13,
        lineHeight: 5,
        textAlign: "left" as const,
        color: "#FFFFFF",
        marginLeft: 5,
    },
    noAccountLabel: {
        fontSize: 16,
        color: "#ffffff",
        fontWeight: "700"
    },
    errorStyle: {
        color: "red",
        textAlign: "center" as const,
        fontSize: 12
    },
    // Customizable Area End
});
