import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  tab:string;
  isAcceptOrDeliver:boolean;
  type:string;
  isDecline:boolean;
  isBakeryOpen:boolean;
  isStoreOpen:boolean;
  // Customizable Area End
}
interface SS {}

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      tab: "one", isStoreOpen: false,
      isAcceptOrDeliver: false,
      type:"",
      isDecline: false,
      isBakeryOpen: false,
    }

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
   
    // Customizable Area End
  }
  // Customizable Area Start

  openAcceptOrDeliverModal = (type: string) => {
    this.setState((prev) => ({ ...prev, isAcceptOrDeliver: true, type }));
  };

  openDeclineModal = () => {
    this.setState((prev) => ({ ...prev, isDecline: true }));
  };

  openBakeryOpenModal = () => {
    this.setState((prev) => ({ ...prev, isBakeryOpen: true }));
  };

  closeAcceptOrDeliverModal = () => {
    this.setState((prev) => ({ ...prev, isAcceptOrDeliver: false }));
  };

  closeDeclineModal = () => {
    this.setState((prev) => ({ ...prev, isDecline: false }));
  };

  closeBakeryOpenModal = () => {
    this.setState((prev) => ({ ...prev, isBakeryOpen: false }));
  };
 
  openStore = () => {
    this.setState((prev) => ({ ...prev, isStoreOpen:true,}));
  };

  handleChange = (event: any, newValue: string) => {
    this.setState({ tab: newValue });
  };
  // Customizable Area End
}
