import React from "react";
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import SettingsController, {
    Props,
} from "./SettingsController.web";


import { Box } from "@mui/material";

import UserProfile from "./UserProfile.web";
import ChangePassword from "./ChangePassword.web";
import ChangeEmail from "./ChangeEmail.web";
import DeleteAccount from "./DeleteAccount.web";

export default class Settings extends SettingsController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        return (
            <Box sx={{
                height: '100%',
                width: "100%"
            }}>
                <Tabs
                    data-test-id="tabs"
                    value={this.state.tab}
                    onChange={(e, v) => this.handleChange(e, v)}
                    variant="scrollable"
                    aria-label="scrollable tabs example"
                    scrollButtons="auto"

                    sx={{
                        '& .MuiTabs-indicator': {
                            backgroundColor: '#C2B30A',
                        },
                    }}
                >
                    <Tab
                        sx={{
                            color: '#ffffff',
                            textTransform: 'none',
                            fontWeight: '700',
                            '&.Mui-disabled': {
                                color: '#78716C',
                                opacity: 1,
                                pointerEvents: 'none',
                            },
                            '&.Mui-selected': {
                                color: '#ffffff',
                            },
                            width: "20%"
                        }}
                        label="Profile" value="1" />
                    <Tab label="Change Email"
                        sx={{
                            color: '#ffffff',
                            textTransform: 'none',
                            fontWeight: '700',
                            '&.Mui-disabled': {
                                color: '#78716C',
                                opacity: 1,
                                pointerEvents: 'none',
                            },
                            '&.Mui-selected': {
                                color: '#ffffff',
                            },
                            width: "20%"
                        }}
                        value="2" />
                    <Tab label="Change Password"
                        sx={{
                            color: '#ffffff',
                            textTransform: 'none',
                            fontWeight: '700',
                            '&.Mui-disabled': {
                                color: '#78716C',
                                opacity: 1,
                                pointerEvents: 'none',
                            },
                            '&.Mui-selected': {
                                color: '#ffffff',
                            },
                            width: "20%"
                        }}
                        value="3" />
                    <Tab label="Edit Payments"
                        sx={{
                            color: '#ffffff',
                            textTransform: 'none',
                            fontWeight: '700',
                            '&.Mui-disabled': {
                                color: '#78716C',
                                opacity: 1,
                                pointerEvents: 'none',
                            },
                            '&.Mui-selected': {
                                color: '#ffffff',
                            },
                            width: "20%"
                        }}
                        value="4" />
                    <Tab label="Delete Account"
                        sx={{
                            color: '#ffffff',
                            textTransform: 'none',
                            fontWeight: '700',
                            '&.Mui-disabled': {
                                color: '#78716C',
                                opacity: 1,
                                pointerEvents: 'none',
                            },
                            '&.Mui-selected': {
                                color: '#ffffff',
                            },
                            width: "20%"
                        }}
                        value="5"
                    />
                </Tabs>
                <Box
                    sx={{
                        height: '90%',
                        overflowY: 'auto',
                    }}
                >
                    {this.state.tab == '1' && <UserProfile />}
                    {this.state.tab == '2' && <ChangePassword/>}
                    {this.state.tab == '3' && <ChangeEmail />}
                    {this.state.tab == '5' && <DeleteAccount />}

                </Box>
            </Box>
        )
    }
}
