import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
    id: string;
    history: { push: any };
}

interface S {
    open: boolean;
}

interface SS {
    id: any;
}

export default class CatalogueCreateController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.state = {
            open: false,
        };
    }

    async componentDidMount() {
        super.componentDidMount();
    }

    handleContinue = () => {
        this.setState({ open: true })
    }

    handleClose = (e: any) => {
        this.setState({ open: false });
        this.props.history.push("/catalogue")
      }
}
