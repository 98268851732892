import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
    id: string;
    history: { push: any };
}

interface S {
    close: string;
    files:any;
}

interface SS {
    id: any;
}

export default class CatalogueCreateController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.state = {
            close: "",
            files:[]
        };
    }

    async componentDidMount() {
        super.componentDidMount();

    }


    handleContinue = () => {
        // this.setState({ close: 'yes' })
        this.props.history.push('/catalogue/details')
    }
    
}
