import { IBlock } from "../../../framework/src/IBlock";
import { BlockComponent } from "../../../framework/src/BlockComponent";

import { runEngine } from "../../../framework/src/RunEngine";

export const webConfigJSON = require("./config.js");

export interface Props {
    open:boolean,
    handleClose:any,
    handleOpen:any,
    openStore:any
}
interface S {
 
}
interface SS {}

export default class OpenBakeryModalController extends BlockComponent<Props, S, SS> {

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    
  }

}