import React from "react";
// Customizable Area Start
import {
  StyleSheet,
} from "react-native";

// Merge Engine - import assets - Start
import { menuImage, btnImage } from "./assets";
import { Typography } from '@builder/component-library';
// Merge Engine - import assets - End

// Merge Engine - Artboard Dimension  - Start

// Merge Engine - Artboard Dimension  - End
// Customizable Area End

import SplashscreenController, { Props, configJSON } from "./SplashscreenController";

export default class Splashscreen extends SplashscreenController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  showTitles = (key:any) => {
    if (key == 1) {
      return {
        title: "Welcome Felipe",
        description: "Here you´ll have whatever you in your kitchen just with a few clicks."
      }
    }else if(key == 2){
      return {
        title: "Our Chefs",
        description: "Discover Chef-Crafted Delights! Dive into a world of culinary wonders from our top chefs."
      }
    }else{
      return {
        title: "Pick your Order",
        description: "Effortless Ordering, Instant Gratification! Create your order seamlessly, then simply pick up your culinary delights at your convenience."
      }
    }
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Merge Engine - render - Start

    return (
      <div style={styles.main}>
        <div style={{ backgroundImage: `url(${menuImage})`, backgroundSize: 'contain', height: "100%", width: "100%" }}>
          <div style={styles.body}>
            <img
              src={require('../assets/login_logo.png')}
              style={styles.logo}
            ></img>
            <Typography testID="splashscreen-title" style={styles.title}>
              {this.showTitles(this.state.key)?.title}
            </Typography>
            <Typography testID="splashscreen-subtitle" style={styles.subTitle}>
            {this.showTitles(this.state.key)?.description}
            </Typography>
            <img
            data-testid="img"
            onClick={()=>{
              this.setState(prevState => {
                const nextNum1 = prevState.key % 3 + 1;
                return { key: nextNum1};
              });
            }}
              src={btnImage}
              style={styles.backIcon}
            ></img>
          </div>
        </div>
      </div>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End
}

// Customizable Area Start
const styles = StyleSheet.create({
  main: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#000000",
    height: "100%",
    fontSize: 25,
    color: "#ffffff"
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: "10px",
    width: "40%",
  },
  logo: {
    height: 80,
    width: 100,
    marginTop: "50px",
    marginBottom: "25px",
  },
  backIcon: {
    height: 56,
    width: 56,
    position: "absolute",
    right: 50,
    bottom: 50
  },
  title: {
    fontSize: 24,
    fontWeight: "700",
    textAlign: "left",
  },
  subTitle: {
    fontSize: 16,
    fontWeight: "400",
    textAlign: "center",
    marginTop: 30
  }
});
// Customizable Area End
