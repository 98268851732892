import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
}

interface S {
    step: string;
    otpRefs: any;
}

interface SS {
}

export default class ChangeEmailController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
        this.state = {
            step: "one",
            otpRefs:[]
        }
    }

    goToNextStep = (step: string) => {
        this.setState({
            step: step
        })
    }


    handleOTPInput = (e: any, fieldName: any, setFieldValue: any) => {
        const { value } = e?.target;
        if (/^\d?$/.test(value)) {
            setFieldValue(fieldName, value);
        }
    };

}
