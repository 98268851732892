



import React from "react";
import { Button, Typography, TextField } from "@mui/material";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import DeclineOrderModalController, { Props } from "./DeclineOrderModalController.web";

export default class DeclineOrderModal extends DeclineOrderModalController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <Dialog
                sx={{
                    borderRadius: '8px',
                    '& .MuiDialog-paper': {
                        backgroundColor: '#000000',
                    },
                    padding: '40px 16px',
                }}

                open={this.props.open}
                aria-describedby="decline-modal-description"
                onClose={this.props.handleClose}
                aria-labelledby="decline-modal-title"
            >
                <DialogTitle id="decline-modal-title">
                    <Typography variant="h6" sx={{
                        fontSize: '32px',
                        fontWeight: 700,
                        textAlign: "center"
                    }}>
                        <span style={{ color: "#ffffff" }}>
                            Are you sure&nbsp;
                        </span>
                        <span style={{ color: "#C2B30A" }}>
                            to Decline the Order
                        </span>
                        <span style={{ color: "#ffffff" }}>
                            ?
                        </span>
                    </Typography>
                    <Typography sx={{
                        fontSize: '14px',
                        padding: "5px 5px",
                        fontWeight: 400,
                        color: "#ffffff",
                        textAlign: "center"
                    }} variant="body1">
                        Keep in mind each time you decline an order the user experience is nevatively impacted
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="decline-modal-description" sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: "center",
                        alignItems: 'center',
                        gap: '8px',
                        width: "100%"
                    }}>

                        <Typography sx={{
                            fontSize: '16px',
                            fontWeight: 700,
                            color: "#ffffff",
                            textAlign: "center"
                        }} variant="body1">
                            Reason to decline
                        </Typography>

                        <TextField
                            sx={{
                                '& .MuiFilledInput-root': {
                                    backgroundColor: "#57534E",
                                    color: "#A8A29E",
                                    borderRadius: '8px',
                                    width: "343px",
                                    '&:hover': {
                                        backgroundColor: "#57534E",
                                    },
                                    '&.Mui-focused': {
                                        backgroundColor: "#57534E",
                                        borderBottom: 'none',
                                    },
                                    '&:before': {
                                        content: 'none',
                                    },
                                    '&:after': {
                                        content: 'none',
                                    },
                                },
                                '& .MuiFilledInput-input': {
                                    color: "#A8A29E",
                                },
                            }}
                            placeholder="Reason to decline the order"
                            multiline
                            rows={4}
                            maxRows={3}
                            variant="filled"
                        />
                    </DialogContentText>
                </DialogContent>
                <DialogActions
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '16px',
                    }}
                >
                    <Button
                        onClick={()=>{
                            this.props.handleClose()
                            this.props.closeAcceptOrDeliverModal()
                        }}
                        sx={{
                            width: '343px',
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            color: '#ffffff',
                            backgroundColor: '#C2B30A',
                            '&:hover': {
                                backgroundColor: '#a59a00',
                            },
                            fontSize: '18px',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                        data-testid="opn-store2"

                    >Yes, Decline</Button>
                    <Button
                        onClick={this.props.handleClose}
                        sx={{
                            width: '343px',
                            height: '55px',
                            padding: '16px 24px',
                            borderRadius: '8px',
                            border: "1px solid #ffffff",
                            color: '#ffffff',
                            backgroundColor: 'transparent',
                            fontSize: '18px',
                            fontWeight: 700,
                            textTransform: 'none'
                        }}
                    >No, Go back</Button>
                </DialogActions>
            </Dialog>
        );
    }
}