import { BlockComponent } from "../../../framework/src/BlockComponent";

export const configJSON = require("./config");

export interface Props {
}

interface S {
}

interface SS {
    id: any;
}

export default class UserProfileController extends BlockComponent<
    Props,
    S,
    SS
> {

    constructor(props: Props) {
        super(props);
    }

    saveProfile = (values: any) => {
        console.log(values)
    }

}
